const initialContrast = () => {
    const wordMarque = document.querySelector( '.wordmarque' );
    let hero = document.querySelector( '.hero' );

    if( hero.classList.contains( 'bg:primary' ) ) {
        wordMarque.classList.add( 'colour:light' );
    } else if( hero.classList.contains( 'bg:light' ) ) {
        wordMarque.classList.add( 'colour:primary' );
        wordMarque.classList.remove( 'colour:light' )
    } else {
        wordMarque.classList.remove( 'colour:light' );
    }
}

export default initialContrast;