const fixedWordMarque = () => {
	const wordMarque = document.querySelector( '.wordmarque' );
	const scrollTop  = window.scrollY;
	let heroHeight;

	if( document.querySelector( '.hero' ).length ) {
		heroHeight = document.querySelector( '.hero' ).getBoundingClientRect().height;
	}
    
	if( document.querySelector( '.story-panel' ) ) {
    	let storyPanelHeight = document.querySelector( '.story-panel' ).getBoundingClientRect().top;
    	if( storyPanelHeight < 0 ) {
    		document.body.classList.add('wordmarque-pinned');
    	} else {
    		document.body.classList.remove('wordmarque-pinned');
    	}
	}

}

export default fixedWordMarque;