import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import kebabCase from '../../helpers/kebabCase';
import Callout from './Callout';
import '../../styles/components/entry.scss';

export default function DirectoryEntry( props ) {
	const [moreActive, setMoreActive] = useState( undefined );

	const handleMore = () => {
		setMoreActive(moreActive => !moreActive);
	}

	const telephone = ( tel ) => {
		let formatted = tel.replace(/\D/g, '');
		return "tel:+" + formatted;
	}

	const handleEntry = ( event ) => {
		let activeEntry = document.querySelector( '.active-entry' );
		event.preventDefault();
		event.currentTarget.parentElement.classList.toggle( 'active-entry' );
		if( activeEntry ) {
			activeEntry.classList.remove( 'active-entry' );
		}
	}

	return (
		<li 
			data-entry={ props.content.node.title } 
			data-categories={ props.content.node.categories.nodes.map((category) => {
				return (
					kebabCase( category.name ) + ", "
				)
			}) } 
			className={ 'entry' }
		>
			<header onClick={(e) => { handleEntry(e);}} >
				<div className={ 'flex spacious' }>
					<div className={ 'flex' }>
						{ props.content.node.title && (
							<h2 className={ 'colour:secondary' }>{ props.content.node.title }</h2>
						)}

						{ props.content.node.categories && (
							<ul className={ 'entry-categories flex uppercase text:sm' }>
								{ props.content.node.categories.nodes.map((category) => (
									<li className={ 'colour:secondary' }>{ category.name }</li>
								)) }
							</ul>
						)}
					</div>
					<span className={ 'info desktop colour:secondary uppercase text:sm' }>Info</span>
					<span className={ 'close' }></span>
				</div>
			</header>

			<div className={ 'entry-content colour:secondary' }>
				<div className={ 'flex' }>
					{ props.content.node.excerpt && (
						<div className={ 'entry-excerpt' } dangerouslySetInnerHTML={{ __html: props.content.node.excerpt }} />
					)}
					{ props.content.node.content && (
						<>
							<div className={ moreActive === true ? "entry-more text:copy entry-more-active" : "entry-more text:copy" } dangerouslySetInnerHTML={{ __html: props.content.node.content }} />
							<p onClick={ handleMore } class="entry-more-toggle">{ moreActive === true ? 'Read less' : 'Read more '}</p>
						</>					
					)}
				</div>
				<aside className={ 'entry-details flex' }>
					<div className={ 'entry-thumbnail' }>
						{ props.content.node.featuredImage != null && (
							<figure className={ 'ar' }>
								{<GatsbyImage className={ 'cover' } image={ props.content.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData }  />}
							</figure>
						)}
					</div>
					<Callout title={ props.content.node.title  } />
					<div className={ 'entry-contact flex text:sm' }>
						<div className={ 'flex spacious' }>
							<ul className={ 'styled-list' }>
								{ props.content.node.directoryFields.address && (
									<li>
										<address>
											<p className={ 'uppercase' }>{ props.content.node.directoryFields.address }</p>
										</address>
									</li>
								)}

								{ props.content.node.directoryFields.telephone && (
									<li>
										<p className={ 'uppercase' }><a className={ 'colour:secondary' } href={ telephone( props.content.node.directoryFields.telephone ) }>{ props.content.node.directoryFields.telephone }</a></p>
									</li>
								)}

								{ props.content.node.directoryFields.emailAddress && (
									<li>
										<p className={ 'uppercase' }><a className={ 'colour:secondary' } href={ 'mailto:' + props.content.node.directoryFields.emailAddress }>Send Email</a></p>
									</li>
								)}

								{ props.content.node.directoryFields.websiteLink && (
									<li>
										<p className={ 'uppercase external-url external-url-symbol' }>
											<a class="colour:secondary" target="_blank" rel="noreferrer" href={ props.content.node.directoryFields.websiteLink }>Visit Website</a>
										</p>
									</li>
								)}
							</ul>
							<Callout title={ props.content.node.title  } />
						</div>

						<div>
						</div>
					</div>
				</aside>
			</div>
		</li>
	);
}