import React, { useState, useEffect, useRef } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useJsApiLoader, GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';

import pin from '../../images/marker.svg';
import '../../styles/components/map.scss';
import '../../styles/content/google-map.scss';
import '../../styles/components/tooltip.scss';

const centre = { lat: 51.54373962914203, lng: -0.05539012554174646 }
const mapStyles = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": "1"
            },
            {
                "lightness": "20"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#ff5935"
            },
            {
                "visibility": "on"
            }
        ]
    }
];

export default function Map( props ) {
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: 'AIzaSyAjfJfxEPtsPhZ0Tff0js5MKRswdXL4suI',	
	});

	const [activeMarker, setActiveMarker] = useState(null);

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return;
		}
		setActiveMarker(marker);
	};

	const telephone = ( tel ) => {
		let formatted = tel.replace(/\D/g, '');
		return "tel:+" + formatted;
	}

	const scrollToEnd = (event) => {
		let toolTipContainer = event.target.closest( '.gm-style-iw-d' );	
		let toolTip = event.target.closest( '.tooltip' );	

		toolTipContainer.scrollTo({
			top: toolTip.scrollHeight,
			behavior: "smooth"
		});
	}

	const scrollToEntry = (event) => {
		let targetEntryName = event.target.dataset.entryTarget;
		let targetEntry = document.querySelector( '[data-entry="' + targetEntryName + '"]' );
		let targetEntryClickHandler = targetEntry.querySelector( 'header' );
		console.log(targetEntryClickHandler.parentElement);
		if( !targetEntryClickHandler.parentElement.classList.contains( 'active-entry' ) ) {
			targetEntryClickHandler.querySelector( 'div' ).click();
		}
		targetEntry.scrollIntoView({
			behavior: 'smooth'
		});
	}

	const prompt = (event) => {
		event.currentTarget.innerHTML = '';
		event.currentTarget.innerHTML = 'Copy email address';
	}

	const removePrompt = (event) => {
		event.currentTarget.innerHTML = '';
		event.currentTarget.innerHTML = 'Send an email';
	}

	const clipboard = (event) => {
		event.preventDefault();
		let address = event.currentTarget.href;

		navigator.clipboard.writeText(address.replace("mailto:", ""));

		event.currentTarget.innerHTML = '';
		event.currentTarget.innerHTML = 'Copied to clipboard!';
	}

	if(isLoaded) {
		return (
			<StaticQuery 
				query={graphql`
				query locations {
				  allWpDirectory {
				    edges {
				      node {
				        directoryFields {
				          address
				          emailAddress
				          fieldGroupName
				          telephone
				          websiteLink
				          latitude
				          longitude
				          googleMapUrl
				          openingHours
				          tooltipThumbnail {
				            localFile {
				              childImageSharp {
				                gatsbyImageData
				              }
				            }
				          }
				        }
				        title
				        categories {
				          nodes {
				            name
				          }
				        }
				      }
				    }
				  }
				}
			`}
			render={mapData => (

				<div className={ 'map' }>
					<GoogleMap center={ centre } zoom={14.4} mapContainerStyle={{ width: '100%', height: '100%' }} options={{
			            styles: mapStyles,
			        }}>
					{ mapData.allWpDirectory.edges.map((location, index) => (
						<Marker
							key={index}
							title={ location.node.title }
          					onClick={() => handleActiveMarker(index)}
							icon={{
								url: pin,
								scaledSize: window.google.maps.Size(16, 16)
							}}
					      	position={{ lat: location.node.directoryFields.latitude, lng: location.node.directoryFields.longitude }}>
					        {activeMarker === index ? (
					    	<InfoWindow 
						    	onCloseClick={() => setActiveMarker(null)}
				    			options={{ 
									pixelOffset: new window.google.maps.Size(0, -22)
				    			}}
				    			position={{ lat: location.node.directoryFields.latitude, lng: location.node.directoryFields.longitude }}
					    		>
					    		<div className={ 'tooltip text-centred bg:primary colour:light' }>
					    			
									{ location.node.categories && (
										<ul className={ 'tooltip-categories ext-centred bg:primary colour:light flex uppercase text:tooltip-category' }>
											{ location.node.categories.nodes.map((category) => (
												<li>{ category.name }</li>
											)) }
										</ul>
									)}
					    			<h2 className={ 'text:tooltip-title' }>{ location.node.title }</h2>
					    			
					    			{ location.node.directoryFields.tooltipThumbnail && (
					    				<GatsbyImage alt={ location.node.title } image={ location.node.directoryFields.tooltipThumbnail.localFile.childImageSharp.gatsbyImageData } />
					    			)}

					    			<div className={ 'address-details' }>
					    				{ location.node.directoryFields.address && (
											<>
							    				<p className={ 'text:tooltip-category uppercase' }>Address</p>
							    				<address className={ 'text:tooltip-title' }>
							    					{ location.node.directoryFields.address }
							    				</address>
						    				</>
					    				)}

					    				{ location.node.directoryFields.googleMapUrl && (
					    					<a className={ 'text:tooltip-title colour:light' } rel="noreferrer" target="_blank" href={ location.node.directoryFields.googleMapUrl }>View on Google Maps</a>
					    				)}
					    			</div>

					    			{ location.node.directoryFields.openingHours && (
						    			<div className={ 'opening-hours' }>
					    					<p className={ 'text:tooltip-category uppercase' }>Opening Hours</p>
					    					<div className={ 'text:tooltip-title' } dangerouslySetInnerHTML={{ __html: location.node.directoryFields.openingHours }} />
						    			</div>
					    			)}

					    			<div className={ 'contact-details' }>
					    				<p className={ 'text:tooltip-category uppercase' }>Contact Details</p>
					    				{ location.node.directoryFields.emailAddress && (
					    					<a 	href={ 'mailto:' + location.node.directoryFields.emailAddress } 
					    						className={ 'text:tooltip-title colour:light' }
					    						onMouseOver={(e) => {
					    							prompt(e);
					    						}}
												onMouseOut={(e) => {
					    							removePrompt(e);
					    						}}
					    						onClick={(e) => {
					    							clipboard(e);
					    						}}>Send an email
					    					</a>
					    				)}
					    				{ location.node.directoryFields.telephone && (
					    					<a className={ 'text:tooltip-title colour:light' } href={ telephone( location.node.directoryFields.telephone ) }>{ location.node.directoryFields.telephone }</a>
					    				)}
					    			</div>
						    		<button data-entry-target={ location.node.title } onClick={(e) => {
						    			scrollToEntry(e);
						    		}} className={ 'bg:light colour:primary text:sm button uppercase' }><span>Jump</span><span>Go</span> to Directory Entry</button>

					    			<div className={ 'view-more text-centred flex' }>
						    			<p className={ 'uppercase' }>
						    				<button onClick={(e) => scrollToEnd(e)} className={ 'underline colour:light text:sm uppercase' } href="#">View more</button>
						    			</p>
					    			</div>
					    		</div>
					    	</InfoWindow>
					    	) : null}
					    </Marker>
					)) }
					</GoogleMap>
				</div>
				)}
			/>
		);
	}
}
