import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import DirectoryEntry from './DirectoryEntry';
import kebabCase from '../../helpers/kebabCase';
import '../../styles/components/directory-wrapper.scss';
import '../../styles/components/filter.scss';

export default function DirectoryWrapper() {
  
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [currentFilter, setCurrentFilter] = useState( 'all' );
    const toggleFilters = ( event ) => {
      setIsFilterVisible(isFilterVisible => !isFilterVisible);
    }

    const setFilterText = ( text, label ) => {
    	let filterReveal = document.querySelector( '.filter-reveal' );
    
    	filterReveal.innerHTML = '';

    	if( label != null ) {
	    	filterReveal.innerHTML = text + label;
    	} else {
    		filterReveal.innerHTML = text;
    	}
    }

    const setFilter = ( event ) => {
    	let selectedCategory = event.currentTarget.dataset.category;
    	let categoryLabel = event.currentTarget.innerHTML;
    	let filterItems = document.querySelectorAll( '.filter-item' );
    	let entries = document.querySelectorAll( '[data-entry]' );

    	removeActive( filterItems );
	    setIsFilterVisible(false);
	    setCurrentFilter( selectedCategory );
    	event.currentTarget.classList.add( 'active-filter' );

    	if( selectedCategory == 'all' ) return;
    	entries.forEach((entry) => {
    		entry.style.display = 'block';
    		if( !entry.dataset.categories.includes( selectedCategory ) ) {
    			entry.style.display = 'none';
    		}
    	});

    	setFilterText( 'Showing: ', categoryLabel );
    }

    const resetFilter = ( event ) => {
    	let selectedCategory = event.currentTarget.dataset.category;
    	let filterItems = document.querySelectorAll( '.filter-item' );
    	let entries = document.querySelectorAll( '[data-entry]' );

    	removeActive( filterItems );
	    setIsFilterVisible(false);
	    setCurrentFilter( selectedCategory );
    	event.currentTarget.classList.add( 'active-filter' );

    	if( selectedCategory != 'all' ) return;

		entries.forEach((entry) => {
			entry.style.display = 'block';
		});

		setFilterText( 'Filter', null );

    }

    const removeActive = ( items ) => {
    	items.forEach(( item ) => {
    		item.classList.remove( 'active-filter' );
    	});
    }

	return (
		<StaticQuery
	      query={graphql`
			query DirectoryEntries {
			  allWpCategory(filter: {id: {ne: "dGVybTox"}, count: {gt: 0}}) {
			    edges {
			      node {
			        name
			        id
			      }
			    }
			  }
  			  allWpDirectory(sort: {order: ASC, fields: menuOrder}) {
			    edges {
			      node {
			        id
			        title
			        content
			        excerpt
			        categories {
			          nodes {
			            id
			            name
			          }
			        }
			        directoryFields {
			          address
			          emailAddress
			          telephone
			          websiteLink
			        }
			        featuredImage {
			          node {
			            localFile {
			              childImageSharp {
			                fixed {
			                  src
			                }
			                gatsbyImageData
			              }
			            }
			          }
			        }
			      }
			    }
			  }
			}
	      `}
	      render={directoryEntry => (
	      	<div className={ 'directory-wrapper' }>
	      		<div>
	      			<header className={ isFilterVisible ? 'filter-opened flex spacious' : 'flex spacious' }>
			      		<p className={ 'text:sm uppercase colour:secondary' }>Business</p>
			      		<button 
			      			onClick={(e) => { toggleFilters(e) }} 
			      			className={ 'filter-reveal text:sm flex uppercase colour:secondary' }>Filter
			      		</button>
					</header>
					{ isFilterVisible && (

						<ul className={ 'filter-block flex uppercase text:sm' }>
				      		{ directoryEntry.allWpCategory.edges.map((index) => {
				      			return (
				      				<>
					      				<li 
					      					onClick={ (e) => { setFilter(e) } } 
					      					data-category={ kebabCase( index.node.name ) } 
					      					className={ currentFilter == kebabCase( index.node.name ) ? 'active-filter filter-item uppercase colour:secondary text:sm' : 'filter-item uppercase colour:secondary text:sm' }>
					      					{ index.node.name }
					      				</li>
				      				</>
				      			)
				      		})}
		      				<li 
				      			className={ currentFilter == 'all' ? 'active-filter filter-item uppercase colour:secondary text:sm' : 'filter-item uppercase colour:secondary text:sm' }
		      					data-category={ 'all' }
		      					onClick={ (e) => { resetFilter(e) } } 
		      				>All
		      				</li>
						</ul>
					)}
	      		</div>

	      		<ul>
	      			{ directoryEntry.allWpDirectory.edges.map((index) => (
	      				<DirectoryEntry content={ index } />
	      			)) }
	      		</ul>
	      	</div>
	      )}
	    />
	);
}