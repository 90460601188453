import React from 'react';

import '../../styles/components/hero.scss';

export default function Hero( props ) {
	return (
		<section className={ 'hero bg:secondary colour:light flex' }>
			{props.children}
		</section>
	);
}
