import React from 'react';
import '../../styles/components/hackney-village-nav.scss';

export default function HackneyVillageNav( props ) {
	return (
		<nav className={ 'hackney-village-nav desktop' }>
			<ul className={ 'flex text:rg spacious colour:light' }>
				<li onClick={ props.mapHandler }>Map</li>
				<li onClick={ props.directoryHandler }>Directory</li>
			</ul>
		</nav>
	);
}