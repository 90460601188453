import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import '../../styles/components/hero-images.scss';

export default function HeroImages( props ) {

	// console.log(props.images.desktopImage.localFile.childImageSharp);
	return (
		<div className={ 'hero-images' }>
			{ props.images.desktopImage != null && (
				<figure className={ 'desktop' }>
					<GatsbyImage className={ 'cover' } image={ props.images.desktopImage.localFile.childImageSharp.gatsbyImageData }  />
				</figure>
			)}
			{ props.images.mobileImage != null && (
				<figure className={ 'mobile' }>
					<GatsbyImage className={ 'cover' } image={ props.images.mobileImage.localFile.childImageSharp.gatsbyImageData }  />
				</figure>
			)}
		</div>
	);
}