import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ResearchNavigation from '../components/navigation/ResearchNav';
import Lead from '../components/hackney-village/Lead';
import HackneyVillageNav from '../components/hackney-village/HackneyVillageNav';
import Directory from '../components/hackney-village/Directory';
import CallToAction from '../components/utils/CallToAction';
import Hero from '../components/research/Hero';
import HeroImages from '../components/research/HeroImages';
import Title from '../components/utils/Title';
import Map from '../components/hackney-village/Map';
import WordMarque from '../components/WordMarque';
import Footer from '../components/utils/Footer';
import Seo from '../components/Seo';
import initialContrast from '../helpers/initialContrast';
import fixedWordMarque from '../helpers/fixedWordMarque';

import '../styles/components/story.scss';
import '../styles/components/hackney-village.scss';

const HackneyVillageTemplate = ({ data }) => {
	const handleMap = () => {
		let map = document.querySelector( '.map' );
		map.scrollIntoView({behavior: 'smooth'});
	}

	const handleDirectory = () => {
		let storyPanel = document.querySelector( '.story-panel' );
		storyPanel.scrollIntoView({behavior: 'smooth'});
	}

	const handleText = () => {
		let synopsis = document.querySelector( '.map-synopsis' );
		synopsis.scrollIntoView({behavior: 'smooth'});
	}

    useEffect(() => {
        initialContrast();
        window.addEventListener('scroll', fixedWordMarque);
        return () => {
            window.removeEventListener('scroll', fixedWordMarque);
        };
    });
	
	return (
		<>
			<Layout>
				<Seo 
					scheme={ 'scheme:secondary' } 
					title={ data.wpResearch.title } 
				/>
				<WordMarque />
				<article className={ 'story hackney-village' }>
					<Lead>
						<Hero>
							<Title title={ data.wpResearch.title } alternative={ data.wpResearch.alternativeTitle } />
							<HeroImages images={ data.wpResearch.heroBlock } />
						</Hero>
						<HackneyVillageNav 
							mapHandler={ handleMap } 
							directoryHandler={ handleDirectory } 
						/>
					</Lead>
					<section>
						<Map />

						<div className={ 'story-panel' }>
							<Directory />
						</div>
					</section>
				</article>
                <Footer classes={ '' } desktop={ data.wpResearch.footerImage.footerImageDesktop } mobile={ data.wpResearch.footerImage.footerImageMobile } />
			</Layout>
		</>
	);
}

export default HackneyVillageTemplate;

export const query = graphql`
	query ResearchItem($id: String) {
	  wpResearch(id: {eq: $id}) {
	    id
	    title
	    slug
	    alternativeTitle {
	      alternativeTitle
	      fieldGroupName
	    }
	    footerImage {
	        footerImageMobile {
	          localFile {
	            childImageSharp {
	              gatsbyImageData
	            }
	          }
	        }
	        footerImageDesktop {
	          localFile {
	            childImageSharp {
	              gatsbyImageData
	            }
	          }
	        }
        }
	    heroBlock {
	      desktopImage {
	        localFile {
	          childImageSharp {
	            gatsbyImageData
	          }
	        }
	      }
	      mobileImage {
	        localFile {
	          childImageSharp {
	            gatsbyImageData
	          }
	        }
	      }
	    }
	  }
	}
`;