import React from 'react';
import DirectoryWrapper from './DirectoryWrapper';

export default function Directory() {
	return (
		<div>
			<h2 className={ 'text:x-lg uppercase colour:secondary text-centred' }>Directory</h2>
			<DirectoryWrapper />
		</div>
	);
}