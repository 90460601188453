import React from 'react';
import '../../styles/components/hero.scss';

export default function Lead( props ) {
	return (
		<section className={ 'hero hero:lead bg:light colour:primary' }>
			<div className={ 'flex' }>
				{props.children}
			</div>
		</section>
	);
}
