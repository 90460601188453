import React, { useState, useEffect } from 'react';

export default function Callout( props ) {
	
	const scrollToMap = ( event ) => {
		let map = document.querySelector( '.map' );
		map.scrollIntoView({
			behavior: 'smooth'
		});

		let mapPinTitle = event.target.dataset.title;
		let selectedMarker = document.querySelector( '[title="'+mapPinTitle+'"]' );
		setTimeout(() => {
			if( selectedMarker ) {
				selectedMarker.click();
			}
		}, 750);
	}

	return (
		<div onClick={(e) => {scrollToMap(e)} } data-title={ props.title } className={ 'callout flex' }>
			<p className={ 'colour:light text:rg text-centred' }>View on map</p>
		</div>		
	);
}